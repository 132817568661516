import React, { useEffect, useState, useContext } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import L from "leaflet";
import {
  GET_ALL_MAPDATA,
  GET_MAPDATA,
  GET_MAPDATA_BY_SELECTION,
} from "../../components/GraphQL/GraphQLMapData";
import { useQuery, useLazyQuery } from "@apollo/client";
import MarkerPopup from "../../components/Layout/MarkerPopup";
import MarkerPopupDetail from "../../components/Layout/MarkerPopupDetail";
import NavigationContext from "../../components/Context/NavigationContext";

function Map() {
  const [map, setMap] = useState(null);
  const [istouchDevice, setIstouchDevice] = useState();
  const [dataArray, setDataArray] = useState();
  const position = [52.520008, 13.404954];
  var markerGroup = [];
  var marker;

  const { loading, error, data } = useQuery(GET_ALL_MAPDATA);

  const {
    activitySelect,
    campingSelect,
    eventSelect,
    foodSelect,
    icecreamSelect,
    lakeSelect,
    placesSelect,
    shopSelect,
    starSelect,
    searchResult,
    getLocation,
  } = useContext(NavigationContext);

  const [getMapDatabySelection, { data: mapData }] = useLazyQuery(
    GET_MAPDATA_BY_SELECTION,
    {
      fetchPolicy: "cache",
      variables: {
        activity: activitySelect,
        //art: selection.artSelect,
        //bike: selection.bikeSelect,
        camping: campingSelect,
        event: eventSelect,
        icecream: icecreamSelect,
        food: foodSelect,
        lake: lakeSelect,
        places: placesSelect,
        shop: shopSelect,
        star: starSelect,
        //weekend: selection.weekendSelect,
      },
    }
  );

  const [getMapDatabySearch, { data: mapItemData }] = useLazyQuery(
    GET_MAPDATA,
    {
      fetchPolicy: "cache-and-network",
      variables: { id: searchResult },
    }
  );

  /// Get Map Data based on Button Selection
  useEffect(() => {
    getMapDatabySelection({
      variables: {
        activity: activitySelect,
        //art: selection.artSelect,
        //bike: selection.bikeSelect,
        camping: campingSelect,
        event: eventSelect,
        icecream: icecreamSelect,
        food: foodSelect,
        lake: lakeSelect,
        places: placesSelect,
        shop: shopSelect,
        star: starSelect,
        //weekend: selection.weekendSelect,
      },
    });
  }, [
    activitySelect,
    campingSelect,
    eventSelect,
    foodSelect,
    icecreamSelect,
    lakeSelect,
    placesSelect,
    shopSelect,
    starSelect,
    getMapDatabySelection,
  ]);

  /// Get Map Data based on Search Results
  useEffect(() => {
    getMapDatabySearch({
      variables: {
        id: searchResult,
      },
    });
  }, [searchResult, getMapDatabySearch]);

  //// Consolidatetion of Markers into one Array to find the bounds and fit to bounds
  function groupMarkers(coordinates) {
    markerGroup.push([Number(coordinates[0]), Number(coordinates[1])]);

    if (map && dataArray.length === 1) {
      map.setView(coordinates, 12);
    } else if (map && markerGroup.length === dataArray.length) {
      var featureGroup = L.featureGroup();
      var n = markerGroup.length;

      for (var i = 0; i < n - 1; i++) {
        var marker = new L.Marker(markerGroup[i]);
        featureGroup.addLayer(marker);
      }
      featureGroup.addLayer(new L.Marker(markerGroup[n - 1]));
      map.fitBounds(featureGroup.getBounds());
    }
  }

  /// Zoom, when marker is clicked on non-touch device
  function clickZoom(coordinates) {
    !istouchDevice && map.setView(coordinates, 14);
  }
  /// Define Icon based on Item
  function getIcon(activity) {
    return L.icon({
      iconUrl: require("../../data/icons/" + activity.toLowerCase() + ".png"),
      iconSize: new L.Point(25, 25),
    });
  }

  ////// Code for the ToDo Icon
  function getInfoIcon(coordinates) {
    return L.icon({
      iconUrl: require("../../data/icons/toDo.png"),
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(12, 12),
      iconAnchor: [-7, 18],
    });
  }

  /// Check if Device is TouchDevice
  useEffect(() => {
    function isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }
    setIstouchDevice(isTouchDevice());
  }, []);

  //// Find unser Location and set Marker
  useEffect(() => {
    if (map) {
      map.locate().on("locationfound", function (e) {
        if (marker) {
          map.removeLayer(marker);
        }
        const iconPerson = new L.Icon({
          iconUrl: require("../../data/icons/location.png"),
          iconAnchor: null,
          popupAnchor: null,
          shadowUrl: null,
          shadowSize: null,
          shadowAnchor: null,
          iconSize: new L.Point(25, 26),
        });
        //map.flyTo(e.latlng);
        map.setView(e.latlng, 14);
        marker = L.marker(e.latlng, { icon: iconPerson });
        marker.addTo(map);
      });
    }
  }, [getLocation]);

  /// This sets the Map Items depending if they have been searched (mapItemDate) or selected via Buttons (mapData)
  useEffect(() => {
    if (mapItemData) {
      setDataArray([mapItemData.getMapData]);
    }
  }, [mapItemData]);

  useEffect(() => {
    if (mapData) {
      setDataArray(mapData.getMapDatabySelection);
    }
  }, [mapData]);

  /*
  if (error) {
    console.log(error);
    alert("DAs ist der Fehler auf der Map Seite");
  }
  */

  return (
    <React.Fragment>
      <MapContainer
        center={position}
        zoom={10}
        scrollWheelZoom={true}
        ref={setMap}
      >
        <TileLayer
          // Use Statiamaps as Data Privacy Friendly Map.
          // In case you don't have an account with stadiamaps.com you can use the Carto Map
          url="https://tiles-eu.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
          attribution="&copy; <a href=https://stadiamaps.com/>Stadia Maps</a>, &copy; <a href=https://openmaptiles.org/>OpenMapTiles</a> &copy; <a href=http://openstreetmap.org>OpenStreetMap</a> contributors; Icons by <a href=https://www.flaticon.com/authors/pixel-perfect>Pixel perfect</a>"
          //url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          // attribution="&copy; <a href=http://www.openstreetmap.org/copyright>OpenStreetMap</a> &copy; <a href=https://carto.com/attributions>CARTO</a>; Icons by <a href=https://www.flaticon.com/authors/pixel-perfect>Pixel perfect</a>"
          subdomains="abcd"
          noWrap="true"
          crossOrigin="null"
        />

        {dataArray &&
          dataArray[0] &&
          dataArray.map((item) => {
            groupMarkers(item.location.coordinates);
            ////// Unhide the below hidden code in case InfoIcon is needed
            if (
              (item.visible === true &&
                item.toDo !== true &&
                item.end_date === null) ||
              (new Date() < new Date(Number(item.end_date)) &&
                new Date(Number(item.start_date)) - 12096e5 < new Date())
            ) {
              return (
                <MarkerPopup
                  key={item.id}
                  item={item}
                  icon={getIcon(item.type)}
                  click={clickZoom}
                  touchDevice={istouchDevice}
                />
              );
            } else if (
              (item.visible === true &&
                item.toDo === true &&
                item.end_date === null) ||
              (new Date() < new Date(Number(item.end_date)) &&
                new Date(Number(item.start_date)) - 12096e5 < new Date())
            ) {
              return (
                <>
                  <MarkerPopup
                    item={item}
                    icon={getIcon(item.type)}
                    click={clickZoom}
                    touchDevice={istouchDevice}
                  />

                  <MarkerPopupDetail
                    item={item}
                    icon={getInfoIcon(item.location.coordinates)}
                    click={clickZoom}
                    touchDevice={istouchDevice}
                  />
                </>
              );
            }
          })}
      </MapContainer>
    </React.Fragment>
  );
}

export default Map;
