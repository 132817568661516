import React, { useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { useQuery, useLazyQuery } from "@apollo/client";
import NavBar from "../../components/Navigation/NavBar";
import ItemsList from "./ItemsList";
import LoginButton from "../../components/Navigation/LoginButton";
import NavigationContext from "../../components/Context/NavigationContext";
///// GraphQL///
import {
  GET_ALL_MAPDATA,
  GET_MAPDATA_BY_SELECTION,
} from "../../components/GraphQL/GraphQLMapData";

function Edit() {
  const {
    activitySelect,
    campingSelect,
    eventSelect,
    foodSelect,
    icecreamSelect,
    lakeSelect,
    placesSelect,
    shopSelect,
    starSelect,
  } = useContext(NavigationContext);

  const { loading, error, data } = useQuery(GET_ALL_MAPDATA);
  const [getMapDatabySelection, { data: mapData }] = useLazyQuery(
    GET_MAPDATA_BY_SELECTION,
    {
      fetchPolicy: "cache",
      variables: {
        activity: activitySelect,
        //art: selection.artSelect,
        //bike: selection.bikeSelect,
        camping: campingSelect,
        event: eventSelect,
        icecream: icecreamSelect,
        food: foodSelect,
        lake: lakeSelect,
        places: placesSelect,
        shop: shopSelect,
        star: starSelect,
        //weekend: selection.weekendSelect,
      },
    }
  );

  useEffect(() => {
    getMapDatabySelection({
      variables: {
        activity: activitySelect,
        //art: selection.artSelect,
        //bike: selection.bikeSelect,
        camping: campingSelect,
        event: eventSelect,
        icecream: icecreamSelect,
        food: foodSelect,
        lake: lakeSelect,
        places: placesSelect,
        shop: shopSelect,
        star: starSelect,
        //weekend: selection.weekendSelect,
      },
    });
  }, [
    activitySelect,
    campingSelect,
    eventSelect,
    foodSelect,
    icecreamSelect,
    lakeSelect,
    placesSelect,
    shopSelect,
    starSelect,
    getMapDatabySelection,
  ]);

  var content = <ItemsList />;

  if (mapData && mapData.getMapDatabySelection.length >= 1) {
    content = <ItemsList items={mapData.getMapDatabySelection} />;
  }

  if (data && mapData && mapData.getMapDatabySelection.length < 1) {
    content = <ItemsList items={data.getAllMapData} />;
  }

  /*
  if (error) {
    console.log(error);
    alert("Hier ist gerde ein Fehler aufgetreten");
  }
*/

  return (
    <React.Fragment>
      <Row className="m-4">
        <Col className="mt-5 nav-text mb-3">
          <LoginButton />
        </Col>
      </Row>
      <Row>
        <Col className="ps-4 pe-4 pt-2">
          <NavBar />
          {content}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Edit;
