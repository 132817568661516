import React, { useContext } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { Stack } from "react-bootstrap";
//////Icons /////
import { BiLinkExternal } from "react-icons/bi";
//////Components/////
import VisibleButton from "../../routes/Edit/VisibleButton";
import ToDoButton from "../../routes/Edit/ToDoButton";
import EditButton from "../../routes/Edit/EditButton";
import NavigationContext from "../../components/Context/NavigationContext";

function MarkerPopup(props) {
  const { item, icon } = props;
  const { authenticated, setAuthenticated } = useContext(NavigationContext);

  return (
    <Marker
      position={item.location.coordinates}
      icon={icon}
      eventHandlers={{
        click: () => {
          props.click(item.location.coordinates);
        },
      }}
    >
      <Popup>
        <div className="popup-header">{item.name}</div>
        <div className="popup-body">
          {item.address.street} {item.address.street && <br />}
          {item.address.zip} {item.address.city}
          <br />
          {item.link && (
            <a href={item.link} target="_blank" rel="noreferrer">
              Website <BiLinkExternal />
            </a>
          )}
          {item.end_date && (
            <>
              <br />
              <br />
              <span>bis: </span>
              {new Date(Number(item.end_date)).toLocaleDateString()}
            </>
          )}
          {item.details && <p className="popupDetails">{item.details}</p>}
        </div>
        {authenticated && (
          <Stack direction="horizontal" className="mt-2">
            <div>
              <ToDoButton item={item} />
            </div>
            <div>
              <VisibleButton item={item} />
            </div>
            <div>
              <EditButton item={item} />
            </div>
          </Stack>
        )}
      </Popup>
      {!props.touchDevice && (
        <Tooltip direction="top" offset={[0, -15]} opacity={1}>
          {item.name}
        </Tooltip>
      )}
    </Marker>
  );
}

export default MarkerPopup;
