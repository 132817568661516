import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import ModalLayout from "../Layout/ModalLayout";
import ItemsModify from "../../routes/Edit/ItemsModify";
import NavigationContext from "../../components/Context/NavigationContext";

////Icons////
import {
  BiLogInCircle,
  BiLogOutCircle,
  BiEditAlt,
  BiMapAlt,
  BiLocationPlus,
} from "react-icons/bi";

function LoginButton() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState(null);
  const location = useLocation();
  const { authenticated, setAuthenticated } = useContext(NavigationContext);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  /*
  const checkEnvironment = () => {
    fetch("/api/environment")
      .then((response) => response.json())
      .then((data) => {
        if (data.environment === "production") {
          // Assuming authentication is determined by being in production
          console.log("Running in production mode");
          alert("Du hast keine Berechtigung dich einzuloggen");
          setAuthenticated(false);
        } else {
          console.log("Not running in production mode");
          setAuthenticated(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching environment data:", error);
        setAuthenticated(false); // Handle error as unauthenticated or based on your logic
      });
  };
  */

  const logOutButton = () => {
    setAuthenticated(false);
    localStorage.removeItem("authToken");
  };

  return (
    <React.Fragment>
      {authenticated ? (
        <React.Fragment>
          <Button
            id="button-logout"
            variant="outline-info"
            onClick={() => logOutButton()}
          >
            <BiLogOutCircle /> Logout
          </Button>
          {url === "/" && (
            <Button
              id="button-edit"
              variant="outline-info"
              onClick={() => navigate("/edit/")}
            >
              <BiEditAlt className="mb-1" />
            </Button>
          )}
          {url === "/edit/" && (
            <Button
              id="button-edit"
              variant="outline-info"
              //size="sm"
              onClick={() => navigate("/")}
            >
              <BiMapAlt className="mb-1" />
            </Button>
          )}
          <Button
            id="button-add"
            variant="outline-info"
            //size="sm"
            onClick={() => setShowModal(true)}
          >
            <BiLocationPlus className="mb-1" />
          </Button>
          <ModalLayout
            open={showModal}
            close={() => setShowModal(false)}
            icon={<BiLocationPlus />}
            titel={"Add Item to Map"}
          >
            <ItemsModify modalClose={() => setShowModal(false)} />
          </ModalLayout>
        </React.Fragment>
      ) : (
        <Button
          id="button-round"
          variant="outline-info"
          //size="sm"
          onClick={() => navigate("/login")}
        >
          <BiLogInCircle /> Login
        </Button>
      )}
    </React.Fragment>
  );
}

export default LoginButton;
