import { createContext, useState } from "react";

const NavigationContext = createContext(null);

export const NavigationContextProvider = (props) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [activitySelect, setActivitySelect] = useState(false);
  //const [artSelect, setArtSelect] = useState(false);
  //const [bikeSelect, setBikeSelect] = useState(false);
  const [campingSelect, setCampingSelect] = useState(false);
  const [eventSelect, setEventSelect] = useState(false);
  const [foodSelect, setFoodSelect] = useState(false);
  const [icecreamSelect, setIcecreamSelect] = useState(false);
  const [lakeSelect, setLakeSelect] = useState(false);
  const [placesSelect, setPlacesSelect] = useState(false);
  const [shopSelect, setShopSelect] = useState(false);
  const [starSelect, setStarSelect] = useState(false);
  //const [weekendSelect, setWeekendSelect] = useState(false);

  const [getLocation, setGetLocation] = useState();
  const [searchResult, setSearchResult] = useState();

  return (
    <NavigationContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        activitySelect,
        setActivitySelect,
        campingSelect,
        setCampingSelect,
        eventSelect,
        setEventSelect,
        foodSelect,
        setFoodSelect,
        icecreamSelect,
        setIcecreamSelect,
        lakeSelect,
        setLakeSelect,
        placesSelect,
        setPlacesSelect,
        shopSelect,
        setShopSelect,
        starSelect,
        setStarSelect,

        searchResult,
        setSearchResult,
        getLocation,
        setGetLocation,
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
