import { gql } from "@apollo/client";

export const GET_ALL_MAPDATA = gql`
  query getAllMapData {
    getAllMapData {
      id
      name
      type
      details
      link
      start_date
      end_date
      toDo
      visible
      location {
        type
        coordinates
      }
    }
  }
`;

export const GET_MAPDATA = gql`
  query getMapData($id: ID) {
    getMapData(id: $id) {
      id
      name
      type
      details
      link
      start_date
      end_date
      toDo
      visible
      location {
        type
        coordinates
      }
      address {
        street
        city
        zip
      }
    }
  }
`;

export const ADD_MAPDATA = gql`
  mutation createMapData(
    $name: String!
    $type: String!
    $details: String
    $street: String
    $zip: Int
    $city: String
    $link: String
    $toDo: Boolean
    $visible: Boolean
    $coordinates: [String]!
    $start_date: String
    $end_date: String
  ) {
    createMapData(
      mapData: {
        name: $name
        type: $type
        details: $details
        street: $street
        zip: $zip
        city: $city
        link: $link
        toDo: $toDo
        visible: $visible
        coordinates: $coordinates
        start_date: $start_date
        end_date: $end_date
      }
    ) {
      id
      name
      type
      details
      link
      start_date
      end_date
      toDo
      visible
      location {
        type
        coordinates
      }
      address {
        street
        city
        zip
      }
    }
  }
`;

export const EDIT_MAPDATA = gql`
  mutation updateMapData(
    $id: ID!
    $name: String!
    $type: String!
    $details: String
    $street: String
    $zip: Int
    $city: String
    $link: String
    $coordinates: [String]!
    $toDo: Boolean
    $visible: Boolean
    $start_date: String
    $end_date: String
  ) {
    updateMapData(
      id: $id
      details: {
        name: $name
        type: $type
        details: $details
        street: $street
        zip: $zip
        city: $city
        link: $link
        toDo: $toDo
        visible: $visible
        coordinates: $coordinates
        start_date: $start_date
        end_date: $end_date
      }
    ) {
      id
      name
      type
      details
      link
      start_date
      end_date
      toDo
      visible
      location {
        type
        coordinates
      }
      address {
        street
        city
        zip
      }
    }
  }
`;

export const DELETE_MAPDATA = gql`
  mutation deleteMapData($id: ID) {
    deleteMapData(id: $id)
  }
`;

export const EDIT_TODO = gql`
  mutation updateToDo($id: ID!, $toDo: Boolean) {
    updateToDo(id: $id, details: { toDo: $toDo }) {
      id
      name
      type
      details
      link
      start_date
      end_date
      toDo
      visible
      location {
        type
        coordinates
      }
      address {
        street
        city
        zip
      }
    }
  }
`;

export const EDIT_VISIBLE = gql`
  mutation updateVisible($id: ID!, $visible: Boolean) {
    updateVisible(id: $id, details: { visible: $visible }) {
      id
      name
      type
      details
      link
      start_date
      end_date
      toDo
      visible
      location {
        type
        coordinates
      }
      address {
        street
        city
        zip
      }
    }
  }
`;

export const GET_MAPDATA_BY_SELECTION = gql`
  query getMapDatabySelection(
    $activity: Boolean
    $art: Boolean
    $bike: Boolean
    $camping: Boolean
    $event: Boolean
    $food: Boolean
    $icecream: Boolean
    $lake: Boolean
    $places: Boolean
    $star: Boolean
    $shop: Boolean
    $weekend: Boolean
  ) {
    getMapDatabySelection(
      details: {
        activity: $activity
        art: $art
        bike: $bike
        camping: $camping
        event: $event
        food: $food
        icecream: $icecream
        lake: $lake
        places: $places
        star: $star
        shop: $shop
        weekend: $weekend
      }
    ) {
      id
      name
      type
      details
      link
      start_date
      end_date
      toDo
      visible
      location {
        type
        coordinates
      }
      address {
        street
        city
        zip
      }
    }
  }
`;

export const GET_SEARCHRESULT = gql`
  query getInfosForSearchBar($textSearch: String) {
    getInfosForSearchBar(details: { textSearch: $textSearch }) {
      id
      name
      type
    }
  }
`;
