import React, { useState } from "react";
import ModalLayout from "../../components/Layout/ModalLayout";
import ItemsModify from "./ItemsModify";
//////Icons /////
import { BiEditAlt } from "react-icons/bi";

function EditButton({ item }) {
  const [modalID, setModalID] = useState();
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <BiEditAlt
        className="list-icon edit"
        onClick={() => {
          setShowModal(true);
          setModalID(item.id);
        }}
      />
      <ModalLayout
        open={showModal}
        close={() => setShowModal(false)}
        icon={<BiEditAlt />}
        titel={"Edit Item"}
      >
        <ItemsModify id={modalID} modalClose={() => setShowModal(false)} />
      </ModalLayout>
    </React.Fragment>
  );
}

export default EditButton;
