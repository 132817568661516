import React from "react";
import NavBar from "../../components/Navigation/NavBar";
import SearchBar from "../../components/Navigation/SearchBar";
import { Row, Col } from "react-bootstrap";
import LeafletMap from "../Map/Map";
import LoginButton from "../../components/Navigation/LoginButton";

function Home() {
  return (
    <React.Fragment>
      <Row className="m-4">
        <Col className="mt-5 login-button">
          <LoginButton />
        </Col>
        <Col md="auto" className="mt-5 mb-3">
          <SearchBar />
        </Col>
      </Row>
      <Row>
        <Col className="ps-4 pe-4 pt-2">
          <NavBar />
          <LeafletMap />
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Home;
