import React from "react";
import { Navigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_MAPDATA,
  GET_ALL_MAPDATA,
  EDIT_MAPDATA,
  ADD_MAPDATA,
} from "../../components/GraphQL/GraphQLMapData";
import ItemsTemplate from "./ItemsTemplate";

function ItemsModify(props) {
  /// Update Items ///
  const id = props.id;
  const { data: mapData } = useQuery(GET_MAPDATA, {
    variables: {
      id: id,
    },
  });
  const [updateItem, { error: editError }] = useMutation(EDIT_MAPDATA, {
    refetchQueries: [GET_ALL_MAPDATA, "getAllMapData"],
  });

  /// Create New Item ///
  const [createItem, { error: createError }] = useMutation(ADD_MAPDATA, {
    refetchQueries: [GET_ALL_MAPDATA, "getAllMapData"],
  });

  function handleSubmitt(selection) {
    console.log("Im Handle Submitt");

    ///Update Item in case ID available////
    if (selection.id) {
      updateItem({
        variables: {
          id: selection.id,
          name: selection.name,
          type: selection.type,
          zip: selection.zip,
          city: selection.city,
          street: selection.street,
          coordinates: selection.coordinates,
          details: selection.details,
          link: selection.link,
          toDo: selection.toDo,
          visible: selection.visible,
          start_date: selection.start_date,
          end_date: selection.end_date,
        },
      });
    } else {
      //// Create new Item in case no ID is availble
      createItem({
        variables: {
          name: selection.name,
          type: selection.type,
          zip: selection.zip,
          city: selection.city,
          street: selection.street,
          coordinates: selection.coordinates,
          details: selection.details,
          link: selection.link,
          toDo: selection.toDo,
          visible: selection.visible,
          start_date: selection.start_date,
          end_date: selection.end_date,
        },
      });
    }

    props.modalClose();
  }

  if (editError) {
    console.log(editError);
    alert("You are not allowed to edit");
    return <Navigate to={"/"} />;
  }

  if (createError) {
    console.log(createError);
    alert("You are not allowed to add");
  }

  return (
    <React.Fragment>
      {mapData && (
        <ItemsTemplate
          handleData={handleSubmitt}
          details={mapData.getMapData}
          id={id}
        />
      )}
    </React.Fragment>
  );
}

export default ItemsModify;
