import React, { useEffect, useState, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { InputGroup, Form } from "react-bootstrap";
import { BiSearch, BiCurrentLocation } from "react-icons/bi";
import NavigationContext from "../../components/Context/NavigationContext";
import { GET_SEARCHRESULT } from "../../components/GraphQL/GraphQLMapData";

function SearchBar(props) {
  const [textSearch, setTextSearch] = useState("");
  const [searchResults, setSearchResults] = useState();
  const {
    setActivitySelect,
    setCampingSelect,
    setEventSelect,
    setFoodSelect,
    setIcecreamSelect,
    setLakeSelect,
    setPlacesSelect,
    setShopSelect,
    setStarSelect,
    setSearchResult,
    setGetLocation,
  } = useContext(NavigationContext);

  const [getInfosForSearchBar, { data: searchData }] = useLazyQuery(
    GET_SEARCHRESULT,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        textSearch: textSearch,
      },
      onCompleted: (searchData) => {
        setSearchResults(searchData.getInfosForSearchBar);
      },
    }
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (textSearch.length >= 3) {
        getInfosForSearchBar({
          variables: {
            textSearch: textSearch,
          },
        });
      } else {
        setSearchResults();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [textSearch]);

  function handleSubmit(e) {
    setTextSearch("");
    setSearchResults();
    ////If a Search Result is selected, all other Options should be deselected
    setActivitySelect(false);
    setCampingSelect(false);
    setEventSelect(false);
    setFoodSelect(false);
    setIcecreamSelect(false);
    setLakeSelect(false);
    setPlacesSelect(false);
    setShopSelect(false);
    setStarSelect(false);
    setSearchResult(e);
  }
  return (
    <React.Fragment>
      <InputGroup>
        <InputGroup.Text id="search-box-icon">
          <BiSearch />
        </InputGroup.Text>
        <Form.Control
          size="sm"
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
          placeholder="Suchen..."
          name="Search"
          id="search-box-input-fied"
        />
        <InputGroup.Text id="location-button-icon">
          <div onClick={(e) => setGetLocation(e)}>
            <BiCurrentLocation style={{ fontSize: 20 }} />
          </div>
        </InputGroup.Text>
      </InputGroup>
      <div
        className={
          searchResults && textSearch.length >= 3
            ? "search-result-dropdown"
            : "search-result-dropdown-hidden"
        }
      >
        {searchResults && searchResults.length >= 1 && (
          <div className="search-list-headline">
            <i>{textSearch}</i> wurde gefunden in:
          </div>
        )}
        {searchResults && searchResults.length < 1 && (
          <div className="search-list-headline">
            <i>{textSearch}</i> wurde nicht gefunden
          </div>
        )}
        {searchResults &&
          searchResults.map((item) => {
            return (
              <div
                key={item.id}
                className="search-list-item"
                onClick={() => handleSubmit(item.id)}
              >
                {item.name}
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
}

export default SearchBar;
