import React from "react";
import { Marker } from "react-leaflet";

function MarkerPopup(props) {
  const item = props.item;
  const icon = props.icon;

  return (
    <Marker
      position={[item.location.coordinates[0], item.location.coordinates[1]]}
      icon={icon}
    ></Marker>
  );
}

export default MarkerPopup;
