import React from "react";
import ReactDOM from "react-dom/client";
import { Container } from "react-bootstrap";
import App from "./App";
import "./custom.scss";
////Components////
import ApolloWrapper from "./ApolloWrapper";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ApolloWrapper>
      <Container fluid>
        <div className="container-style">
          <App />
        </div>
      </Container>
    </ApolloWrapper>
  </React.StrictMode>
);
