import React, { useContext, useEffect, useState } from "react";
import { Table, Badge, Popover, OverlayTrigger } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import NavigationContext from "../../components/Context/NavigationContext";
//////Icons /////
import { BiCommentDetail } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";
//////GraphQL /////
import {
  DELETE_MAPDATA,
  GET_ALL_MAPDATA,
} from "../../components/GraphQL/GraphQLMapData";
//////Components/////
import EditButton from "./EditButton";
import ToDoButton from "./ToDoButton";
import VisibleButton from "./VisibleButton";
import EditFilter from "./EditFilter";

function ItemsList(props) {
  const { activitySelect, eventSelect } = useContext(NavigationContext);
  const [visible, setVisible] = useState(true);
  const [toDo, setToDo] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [type, setType] = useState();
  const [itemTypes, setItemTypes] = useState();
  const [amountShown, setAmountShown] = useState(0);
  const [amountHidden, setAmountHidden] = useState(0);

  const [deleteMapData] = useMutation(DELETE_MAPDATA, {
    refetchQueries: [
      GET_ALL_MAPDATA,
      {
        fetchPolicy: "network",
      },
      "getAllMapData",
    ],
  });

  // This is the delete Function for GraphQL
  function handleDelete(id) {
    deleteMapData({
      variables: {
        id: id,
      },
    });
  }

  ////Function to select only relevant categories

  useEffect(() => {
    var result = [];
    if (props.items) {
      var lookup = {};
      var items = props.items;

      for (var item, i = 0; (item = items[i++]); ) {
        var type = item.type;
        if (!(type in lookup)) {
          lookup[type] = 1;
          result.push(type);
        }
      }
      result.sort().unshift("Select Type...");
    }
    setItemTypes(result);
  }, [props.items]);

  ///// Counter to show the number of items

  useEffect(() => {
    if (props.items) {
      setAmountShown(
        props.items.filter(
          (item) =>
            (visible === item.visible &&
              toDo === item.toDo &&
              (type === item.type || typeof type === "undefined")) ||
            showAll
        ).length
      );
      setAmountHidden(
        props.items.filter(
          (item) =>
            !(
              (visible === item.visible &&
                toDo === item.toDo &&
                (type === item.type || typeof type === "undefined")) ||
              showAll
            )
        ).length
      );
    }
  }, [visible, toDo, type, showAll, props.items]);

  //// Popover for Details
  const popoverProps = (props) => (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{props.name}</Popover.Header>
      <Popover.Body>{props.details}</Popover.Body>
    </Popover>
  );

  return (
    props.items && (
      <React.Fragment>
        <EditFilter
          visible={setVisible}
          toDo={setToDo}
          showAll={setShowAll}
          type={setType}
          itemTypes={itemTypes}
        />

        <div className="mx-5">
          <div className="m-1 d-flex flex-nowrap align-items-center ">
            Shown: <div className="ps-1" />
            <Badge bg="info" pill>
              {amountShown}
            </Badge>
            <div className="ps-2" />
            Hidden: <div className="ps-1" />
            <Badge bg="secondary" pill>
              {amountHidden}
            </Badge>
          </div>
          <Table responsive="lg" className="mt-3">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Street</th>
                <th scope="col">City</th>
                {(activitySelect || eventSelect) && <th scope="col">Date</th>}
                <th scope="col">ToDo</th>
                <th scope="col">Visible</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {props.items &&
                props.items.map((item) => {
                  if (
                    (visible === item.visible &&
                      toDo === item.toDo &&
                      (type === item.type || typeof type === "undefined")) ||
                    showAll
                  ) {
                    return (
                      <tr key={item.id}>
                        {!item.details && <td>{item.name}</td>}

                        {item.details && (
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={popoverProps(item)}
                            delay={{ show: 50, hide: 200 }}
                          >
                            <td className="d-flex align-items-center">
                              {item.name} <div className="p-1" />
                              <BiCommentDetail />
                            </td>
                          </OverlayTrigger>
                        )}

                        <td>{item.type}</td>
                        <td>{item.address.street}</td>
                        <td>{item.address.city}</td>
                        {(activitySelect || eventSelect) && (
                          <td>
                            {item.end_date &&
                              new Date(
                                Number(item.end_date)
                              ).toLocaleDateString()}
                          </td>
                        )}
                        <td>
                          <ToDoButton item={item} />
                        </td>
                        <td>
                          <VisibleButton item={item} />
                        </td>
                        <td className="text-nowrap right-text">
                          <EditButton item={item} />
                          <TiDeleteOutline
                            className="list-icon delete"
                            onClick={() => handleDelete(item.id)}
                          />
                        </td>
                      </tr>
                    );
                  } else {
                  }
                })}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    )
  );
}

export default ItemsList;
