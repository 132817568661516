import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import NavigationContext from "../Context/NavigationContext";
import { Form, Col, Button, Container } from "react-bootstrap";
import { BiLogInCircle, BiLockAlt, BiSolidUser } from "react-icons/bi";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { authenticated, setAuthenticated } = useContext(NavigationContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    fetch("/auth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((res) => res.json())
      .then((res) => {
        if ("success" === res.message) {
          localStorage.setItem("authToken", res.token);
          setAuthenticated(true);
          navigate("/edit/");
        } else {
          window.alert("Wrong Username or Password");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const test = () => {
    console.log("click");
    const authToken = localStorage.getItem("authToken");
    console.log(authToken);

    fetch("/protected", {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  };

  return (
    <React.Fragment className="mb-3">
      <Container>
        <Col xs={8} md={6} className="mx-auto">
          <Form onSubmit={handleSubmit} className="mb-3">
            <Form.Group className="mb-4 mt-5">
              <Form.Label>
                <BiSolidUser className="icon-tab" />
                Username
              </Form.Label>
              <Form.Control
                placeholder="Username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3 mt-3" controlId="formBasicPassword">
              <Form.Label>
                <BiLockAlt className="icon-tab" />
                Password
              </Form.Label>
              <Form.Control
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button id="button-round" variant="outline-info" type="submit">
              <BiLogInCircle /> Login
            </Button>
          </Form>
          <Button
            variant="primary"
            type="submit"
            onClick={(e) => navigate("/edit/")}
          >
            Cancel
          </Button>
          {/*-
          <Button variant="primary" type="submit" onClick={(e) => test()}>
            Validate
          </Button>
         --*/}
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default LoginPage;
