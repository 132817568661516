import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function ModalLayout(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(props.close);

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={props.open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            {props.titel}
            <div className="p-1" /> {props.icon}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Close - don't save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ModalLayout;
