import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import NavigationContext from "../../components/Context/NavigationContext";

export const ProtectedRoute = () => {
  const { authenticated } = useContext(NavigationContext);

  if (authenticated) {
    return <Outlet />;
  } else {
    return <Navigate to={"/"} />;
  }
};
