import React, { useState } from "react";
import { Form, Stack } from "react-bootstrap";
////Icons////
import { BiInfoCircle, BiShow, BiHide, BiReset } from "react-icons/bi";

function EditFilter(props) {
  const [type, setType] = useState();
  const [visible, setVisible] = useState(true);
  const [toDo, setToDo] = useState(false);

  function selectItem(e) {
    setType(e.target.value);
    if (e.target.value === "Select Type...") {
      props.type(undefined);
    } else {
      props.type(e.target.value);
    }
    props.showAll(false);
  }

  function clickShowItem() {
    setVisible((state) => !state);
    props.visible((state) => !state);
    props.showAll(false);
  }

  function clickToDo() {
    setToDo((state) => !state);
    props.toDo((state) => !state);
    props.showAll(false);
  }

  function crearFilter() {
    setType("Select Type...");
    props.showAll(true);
    props.type(undefined);
  }

  return (
    <React.Fragment>
      <Stack direction="horizontal" gap={3} className="ms-5 mb-3">
        {props.itemTypes && props.itemTypes.length > 2 && (
          <div style={{ width: "200px" }}>
            <Form.Select value={type} onChange={(e) => selectItem(e)}>
              {props.itemTypes.map((itemType) => {
                return <option value={itemType}>{itemType}</option>;
              })}
            </Form.Select>
          </div>
        )}
        {props.itemTypes && props.itemTypes.length < 3 && (
          <div style={{ width: "200px" }}>
            <Form.Select value={"Nothing to select"} disabled>
              <option>{"Nothing to select"}</option>;
            </Form.Select>
          </div>
        )}
        <div className="ms-2 d-flex flex-nowrap align-items-center justify-content-center">
          <BiInfoCircle style={{ color: "green" }} className="icon-tab" /> ToDo:
          <div className="ps-2" />
          <Form.Check
            type="switch"
            checked={toDo}
            onChange={() => {
              clickToDo();
            }}
          />
        </div>
        <div className="ms-2 d-flex flex-nowrap align-items-center justify-content-center">
          Visible:
          {visible === true && (
            <BiShow
              className="list-icon show"
              onClick={() => {
                clickShowItem();
              }}
            />
          )}
          {visible !== true && (
            <BiHide
              className="list-icon hide"
              onClick={() => {
                clickShowItem();
              }}
            />
          )}
        </div>
        <div className="ms-2 d-flex flex-nowrap align-items-center justify-content-center">
          Clear Filter:
          <BiReset
            className="list-icon"
            onClick={() => {
              crearFilter();
            }}
          />
        </div>
      </Stack>
    </React.Fragment>
  );
}

export default EditFilter;
