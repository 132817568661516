import React from "react";
import { useMutation } from "@apollo/client";
//////Icons /////
import { BiShow, BiHide } from "react-icons/bi";
//////GraphQL /////
import {
  GET_ALL_MAPDATA,
  EDIT_VISIBLE,
} from "../../components/GraphQL/GraphQLMapData";

function VisibleButton({ item }) {
  const [updateVisible] = useMutation(EDIT_VISIBLE, {
    refetchQueries: [
      GET_ALL_MAPDATA,
      {
        fetchPolicy: "network",
      },
      "getAllMapData",
    ],
  });

  function clickShowItem(item) {
    if (item.visible) {
      updateVisible({
        variables: {
          id: item.id,
          visible: false,
        },
      });
    } else {
      updateVisible({
        variables: {
          id: item.id,
          visible: true,
        },
      });
    }
  }

  return (
    <React.Fragment>
      {item.visible === true && (
        <BiShow
          className="list-icon show"
          onClick={() => {
            clickShowItem(item);
          }}
        />
      )}
      {item.visible !== true && (
        <BiHide
          className="list-icon hide"
          onClick={() => {
            clickShowItem(item);
          }}
        />
      )}
    </React.Fragment>
  );
}

export default VisibleButton;
