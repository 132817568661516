import React, { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { itemTypes } from "../../data/itemTypes";
//////GraphQL /////
import { GET_MAPDATA } from "../../components/GraphQL/GraphQLMapData";
//////Icons /////
import {
  BiMap,
  BiShow,
  BiHide,
  BiInfoCircle,
  BiEditAlt,
  BiCalendarPlus,
  BiCalendarX,
  BiDetail,
  BiBuildingHouse,
  BiBookmark,
} from "react-icons/bi";
import { TbWorld } from "react-icons/tb";

function ItemsTemplate(props) {
  /// Definition of different Input Variables
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [details, setDetails] = useState("");
  const [coordinates, setCoordinates] = useState("");
  const [link, setLink] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState();
  const [toDo, setToDo] = useState(false);
  const [visible, setVisible] = useState(true);

  //// Load Data in case ID is available
  const {
    data: mapItemData,
    loading,
    error,
  } = useQuery(GET_MAPDATA, {
    fetchPolicy: "cache-and-network",
    variables: { id: props.id },
    onCompleted: (mapItemData) => {
      if (mapItemData) {
        const data = mapItemData.getMapData;
        setName(data.name);
        setType(data.type);
        setStreet(data.address.street);
        setZip(data.address.zip);
        setCity(data.address.city);
        setCoordinates(data.location.coordinates);
        setDetails(data.details);
        setLink(data.link);
        setToDo(data.toDo);
        setVisible(data.visible);
        if (data.end_date) {
          setEndDate(
            new Date(Number(data.end_date)).toISOString().split("T")[0]
          );
        }
        if (data.start_date) {
          setStartDate(
            new Date(Number(data.start_date)).toISOString().split("T")[0]
          );
        }
      }
    },
  });

  ///Data from the Input Template is transfered to the ItemsModify Component
  /// to be transferred to the Backend

  function handleSubmit(e) {
    e.preventDefault();
    var selection = {};

    console.log("Das ist e" + e);

    if (props.id) {
      selection.id = props.id;
    }

    /// Convert the Coordinates to a String Array to transfer to Backend
    let strArray = [];
    if (typeof coordinates === "object") {
      coordinates.forEach((str) => {
        strArray.push(str.toString());
      });
    } else {
      let splitArray = coordinates.split(",");
      strArray = splitArray;
    }

    selection.name = name;
    selection.type = type;
    selection.details = details;
    selection.coordinates = strArray;
    selection.link = link;
    selection.start_date = start_date;
    selection.end_date = end_date;
    selection.toDo = toDo;
    selection.visible = visible;
    selection.city = city;
    selection.street = street;
    selection.zip = Number(zip);

    if (
      selection.name &&
      selection.type !== "Select Type..." &&
      selection.type &&
      checkArray(selection.coordinates)
    ) {
      props.handleData(selection);
    } else {
      alert("Please correct Name, Type or Coordinates");
    }
  }

  function checkArray(arr) {
    return arr.length === 2 && arr.every((el) => typeof el === "string");
  }

  function toDoHandler(e) {
    e.currentTarget.checked ? setToDo(true) : setToDo(false);
  }

  function visibleHandler(e) {
    e.currentTarget.checked ? setVisible(true) : setVisible(false);
  }

  return (
    <React.Fragment>
      <Form className="p-2">
        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3" controlId="formName">
            <Form.Label className={`mandatory-field ${!name ? "empty" : ""}`}>
              <BiEditAlt className="icon-tab" />
              Name
            </Form.Label>
            <Form.Control
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="Priroty" md={3}>
            <Form.Label className={`mandatory-field ${!type ? "empty" : ""}`}>
              <BiBookmark className="icon-tab" />
              Type
            </Form.Label>
            <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
              {itemTypes.map((itemType) => {
                return <option value={itemType}>{itemType}</option>;
              })}
            </Form.Select>
          </Form.Group>
        </Row>
        {(type === "Art" || type === "Event") && (
          <Row className="mb-3">
            <Form.Group as={Col} className="mb-3" controlId="formDate">
              <Form.Label>
                <BiCalendarPlus className="icon-tab" />
                Start Date
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                value={start_date}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="formDate">
              <Form.Label
                className={`mandatory-field ${!end_date ? "empty" : ""}`}
              >
                <BiCalendarX className="icon-tab" />
                End Date
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="End Date"
                value={end_date}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
        )}
        <Row className="mb-3">
          <Form.Group
            as={Col}
            className="mb-3"
            controlId="fromDetails"
            md={12}
            lg={8}
          >
            <Form.Label
              className={`mandatory-field ${!coordinates ? "empty" : ""}`}
            >
              <BiMap className="icon-tab" />
              Coordinates
            </Form.Label>
            <Form.Control
              placeholder="12.3456, 56.6789"
              value={coordinates}
              onChange={(e) => setCoordinates(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="fromDetails">
            <Form.Label>
              <BiInfoCircle style={{ color: "green" }} className="icon-tab" />
              ToDo
            </Form.Label>
            <Form.Check type="switch" checked={toDo} onChange={toDoHandler} />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="fromDetails">
            <Form.Label>
              {!visible && <BiHide className="icon-tab" />}
              {visible && <BiShow className="icon-tab" />}
              Visible
            </Form.Label>
            <Form.Check
              type="switch"
              checked={visible}
              onChange={visibleHandler}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3" controlId="formName">
            <Form.Label>
              <BiBuildingHouse className="icon-tab" />
              Street
            </Form.Label>
            <Form.Control
              placeholder="Street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="Priroty" md={2}>
            <Form.Label>ZIP</Form.Label>
            <Form.Control
              placeholder="12345"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group as={Col} controlId="Priroty" md={3}>
            <Form.Label>City</Form.Label>
            <Form.Control
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </Form.Group>
        </Row>
        <Form.Group className="mb-3" controlId="fromDetails">
          <Form.Label>
            <BiDetail className="icon-tab" />
            Details
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Add more details..."
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Form.Group>
        <Row className="mb-3">
          <Form.Group className="mb-3" controlId="formTopic">
            <Form.Label>
              <TbWorld className="icon-tab" />
              Link
            </Form.Label>
            <Form.Control
              placeholder="https://"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </Form.Group>
        </Row>
      </Form>

      <Button
        className="ms-2 mb-3"
        id="button-round"
        onClick={handleSubmit}
        type="submit"
        variant="info"
        disabled={!name || !coordinates || !type}
      >
        Save Changes
      </Button>
    </React.Fragment>
  );
}

export default ItemsTemplate;
