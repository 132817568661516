import React from "react";
import { Form } from "react-bootstrap";
import { useMutation } from "@apollo/client";
//////GraphQL /////
import {
  GET_ALL_MAPDATA,
  EDIT_TODO,
} from "../../components/GraphQL/GraphQLMapData";

function ToDoButton({ item }) {
  const [updateToDo] = useMutation(EDIT_TODO, {
    refetchQueries: [
      GET_ALL_MAPDATA,
      {
        fetchPolicy: "network",
      },
      "getAllMapData",
    ],
  });

  function clickToDo(item) {
    if (item.toDo) {
      updateToDo({
        variables: {
          id: item.id,
          toDo: false,
        },
      });
    } else {
      updateToDo({
        variables: {
          id: item.id,
          toDo: true,
        },
      });
    }
  }

  return (
    <Form.Check
      type="switch"
      checked={item.toDo}
      onChange={() => {
        clickToDo(item);
      }}
    />
  );
}

export default ToDoButton;
