export const itemTypes = [
  "Select Type...",
  "Activity",
  "Art",
  "Bar",
  "Breakfast",
  // "Bike",
  // "Burger",
  "Cake",
  "Camping",
  "Cafe",
  "Event",
  "Fusion",
  "Food",
  "Icecream",
  "Italian",
  "Japan",
  "Lake",
  "Premium",
  "Places",
  "Restaurant",
  "Shop",
  "Star",
  "Tour",
  "Vegan",
  "Weekend",
];
