import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavigationContextProvider } from "./components/Context/NavigationContext";
import { ProtectedRoute } from "./components/Auth/ProtectedRoute";
import Home from "./routes/Home/Home";
import Edit from "./routes/Edit/Edit";
import LoginPage from "./components/Auth/LoginPage";

function App() {
  useEffect(() => {
    fetch("/api/environment")
      .then((response) => response.json())
      .then((data) => {
        if (data.environment === "production") {
          // You are in production environment
          console.log("Running in production mode");
        } else {
          // Not in production
          console.log("Not running in production mode");
        }
      });
  }, []);

  return (
    <div>
      <Router>
        <NavigationContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/edit" element={<Edit />} />
              {/* Other Routes you want to protect */}
            </Route>
          </Routes>
        </NavigationContextProvider>
      </Router>
    </div>
  );
}

export default App;
