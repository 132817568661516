import React, { useContext } from "react";
import { Row, Container, Col, Image } from "react-bootstrap";
import NavigationContext from "../../components/Context/NavigationContext";
import Active from "../../data/icons/activity.png";
import Icecream from "../../data/icons/icecream.png";
//import Art from "../../data/icons/photo.png";
//import Bike from "../../data/icons/bike.png";
import Camping from "../../data/icons/camping.png";
import Events from "../../data/icons/event.png";
import Food from "../../data/icons/restaurant3.png";
import Lake from "../../data/icons/lake.png";
import Places from "../../data/icons/places.png";
import Star from "../../data/icons/star.png";
import Shop from "../../data/icons/shop.png";
//import Weekend from "../../data/icons/love.png";

function NavBar() {
  const {
    activitySelect,
    setActivitySelect,
    campingSelect,
    setCampingSelect,
    eventSelect,
    setEventSelect,
    foodSelect,
    setFoodSelect,
    icecreamSelect,
    setIcecreamSelect,
    lakeSelect,
    setLakeSelect,
    placesSelect,
    setPlacesSelect,
    shopSelect,
    setShopSelect,
    starSelect,
    setStarSelect,
  } = useContext(NavigationContext);

  return (
    <React.Fragment>
      <Container fluid id="nav-container">
        <Row className="justify-content-around">
          {/*-----------IceCream-------------------*/}
          <Col xs={2} sm={1} className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setIcecreamSelect((current) => !current)}
                className="select-item"
              >
                <Image
                  src={Icecream}
                  responsive
                  className={
                    icecreamSelect ? "navicon-png active" : "navicon-png"
                  }
                />
                <br />
                <span
                  className={icecreamSelect ? "nav-text active" : "nav-text"}
                >
                  Eis
                </span>
              </div>
            </div>
          </Col>
          {/*-----------Activity-------------------*/}
          <Col xs={2} sm={1} className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setActivitySelect((current) => !current)}
                className="select-item "
              >
                <Image
                  src={Active}
                  responsive
                  className={
                    activitySelect ? "navicon-png active" : "navicon-png"
                  }
                />
                <br />
                <span
                  className={activitySelect ? "nav-text active" : "nav-text"}
                >
                  Aktivitäten
                </span>
              </div>
            </div>
          </Col>

          {/*-----------Food-------------------*/}
          <Col xs={2} sm={1} className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setFoodSelect((current) => !current)}
                className="select-item"
              >
                <Image
                  src={Food}
                  alt="Essen"
                  responsive
                  className={foodSelect ? "navicon-png active" : "navicon-png"}
                />
                <br />
                <span className={foodSelect ? "nav-text active" : "nav-text"}>
                  Essen
                </span>
              </div>
            </div>
          </Col>
          {/*-----------Shop-------------------*/}
          <Col xs={2} sm={1} className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setShopSelect((current) => !current)}
                className="select-item"
              >
                <Image
                  src={Shop}
                  alt="Shop"
                  responsive
                  className={shopSelect ? "navicon-png active" : "navicon-png"}
                />
                <br />
                <span className={shopSelect ? "nav-text active" : "nav-text"}>
                  Shops
                </span>
              </div>
            </div>
          </Col>

          {/*-----------Lake-------------------*/}
          <Col xs={2} sm={1} className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setLakeSelect((current) => !current)}
                className="select-item"
              >
                <Image
                  src={Lake}
                  alt="Badesee"
                  responsive
                  className={lakeSelect ? "navicon-png active" : "navicon-png"}
                />
                <br />
                <span className={lakeSelect ? "nav-text active" : "nav-text"}>
                  Badesee
                </span>
              </div>
            </div>
          </Col>
          {/*-----------Places-------------------*/}
          <Col xs={2} sm={1} className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setPlacesSelect((current) => !current)}
                className="select-item"
              >
                <Image
                  src={Places}
                  responsive
                  className={
                    placesSelect ? "navicon-png active" : "navicon-png"
                  }
                />
                <br />
                <span className={placesSelect ? "nav-text active" : "nav-text"}>
                  Orte
                </span>
              </div>
            </div>
          </Col>

          {/*-----------Event-------------------*/}
          <Col xs={2} sm={1} className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setEventSelect((current) => !current)}
                className="select-item"
              >
                <Image
                  src={Events}
                  responsive
                  className={eventSelect ? "navicon-png active" : "navicon-png"}
                />
                <br />
                <span className={eventSelect ? "nav-text active" : "nav-text"}>
                  Events
                </span>
              </div>
            </div>
          </Col>
          {/*-----------Camping-------------------*/}
          <Col xs={2} sm={1} className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setCampingSelect((current) => !current)}
                className="select-item"
              >
                <Image
                  src={Camping}
                  responsive
                  className={
                    campingSelect ? "navicon-png active" : "navicon-png"
                  }
                />
                <br />
                <span
                  className={campingSelect ? "nav-text active" : "nav-text"}
                >
                  Camping
                </span>
              </div>
            </div>
          </Col>
          {/*-----------Highlights-------------------*/}
          <Col xs={2} sm={1} className="text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setStarSelect((current) => !current)}
                className="select-item"
              >
                <Image
                  src={Star}
                  responsive
                  className={starSelect ? "navicon-png active" : "navicon-png"}
                />
                <br />
                <span className={starSelect ? "nav-text active" : "nav-text"}>
                  Highlights
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default NavBar;
